import React, {useCallback, useState, useEffect} from 'react';
import LandingLayout from "../../components/layouts/LandingLayout";
import { Heading, Text, Link } from "@chakra-ui/react"
import Hero from "../../components/sections/Hero";

const LandingPage = () => {

    return (
      <LandingLayout>
        <Hero
          title="Where amazing product's being!"
          subtitle="Have a cool idea that solves a burning problem? BUT don't know where to start? You've come to the right place."
          image="/img/undraw_product_tour_foyt.svg"
          ctaText="LEARN MORE"
          ctaLink="https://docs.google.com/forms/d/e/1FAIpQLSdl1_3cvTSEnVqlVP2uTwOE3OqcfNxbAZ9MaT8Z_hzCFtVEtg/viewform?usp=sf_link"
          />
      </LandingLayout>
    );
};

export default LandingPage
