import React, {Component} from 'react';
import LandingPage from './Pages/Landing/LandingPage';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

class App extends Component {

  constructor(props) {
    super(props);
  }

  render(props) {
    return (
      <Router>
        <Switch>
            <Route path="/">
              <LandingPage />
            </Route>
            <Route path="/home">
              <LandingPage />
            </Route>
        </Switch>
      </Router>
    )
  }

}

export default App;
