import React from "react";
import { Link } from "react-router-dom";
import { Box, Flex, Text, Button, Menu, MenuButton, MenuList, MenuItem, useColorMode, IconButton } from "@chakra-ui/react";
import { SettingsIcon } from '@chakra-ui/icons'
import Logo from "../ui/Logo";

const Settings = ({isLast, ...rest}) => {
  const { colorMode, toggleColorMode } = useColorMode()
  return (
    <Menu>
      <IconButton as={MenuButton} icon={<SettingsIcon />} />
      <MenuList>
        <MenuItem onClick={toggleColorMode}>Toggle {colorMode === "light" ? "Dark" : "Light"}</MenuItem>
      </MenuList>
    </Menu>
  )
}

const CutomMenuItem = ({ children, isLast, to = "/", ...rest }) => {
  return (
    <Text
      mb={{ base: isLast ? 0 : 8, sm: 0 }}
      mr={{ base: 0, sm: isLast ? 0 : 8 }}
      display="block"
      {...rest}
    >
      <Link to={to}>{children}</Link>
    </Text>
  );
};

const CutomLinkItem = ({ children, isLast, to = "/", ...rest }) => {
  return (
    <Text
      mb={{ base: isLast ? 0 : 8, sm: 0 }}
      mr={{ base: 0, sm: isLast ? 0 : 8 }}
      display="block"
      {...rest}
    >
      <a target="_blank" href={to}>{children}</a>
    </Text>
  );
};

const CloseIcon = () => (
  <svg width="24" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
    <title>Close</title>
    <path
      fill="black"
      d="M9.00023 7.58599L13.9502 2.63599L15.3642 4.04999L10.4142 8.99999L15.3642 13.95L13.9502 15.364L9.00023 10.414L4.05023 15.364L2.63623 13.95L7.58623 8.99999L2.63623 4.04999L4.05023 2.63599L9.00023 7.58599Z"
    />
  </svg>
);

const MenuIcon = () => (
  <svg
    width="24px"
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    fill="black"
  >
    <title>Menu</title>
    <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
  </svg>
);

const Footer = (props) => {
  const [show, setShow] = React.useState(false);
  const toggleMenu = () => setShow(!show);

  return (
    <Flex
      as="nav"
      align="center"
      justify="space-between"
      wrap="wrap"
      w="100%"
      mb={8}
      p={8}
      bg={["primary.500", "primary.500", "transparent", "transparent"]}
      color={["black", "black", "primary.700", "primary.700"]}
      {...props}
    >
      <Flex align="center">
        <Logo
          w="190px"
          color={["black", "black", "primary.500", "primary.500"]}
        />
        <Text>© 2021 Product.st . All rights reserved</Text><br/>
      </Flex>


      <Box
        display={{ base: show ? "block" : "block", md: "block", sm: 'block' }}
        flexBasis={{ base: "100%", md: "auto", sm: 'auto' }}
      >
        <Flex
          align="center"
          justify={["center", "space-between", "flex-end", "flex-end"]}
          direction={["column", "row", "row", "row"]}
          pt={[4, 4, 0, 0]}
        >
          <CutomLinkItem to="https://docs.google.com/forms/d/e/1FAIpQLSdl1_3cvTSEnVqlVP2uTwOE3OqcfNxbAZ9MaT8Z_hzCFtVEtg/viewform?usp=sf_link">Contact Us</CutomLinkItem>
        </Flex>
      </Box>
    </Flex>
  );
};

export default Footer;
